import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/header.css";
import { Navigate, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logo-white.png';
import LogoBlue from '../../assets/images/logo.png';

const Header = (props) => {
  const location = useLocation();

  return (
    <>
      <div className="header-bar">
        <div className="header-outer-wrapper">
          <div className="header-inner-wrapper">
          <div className="logo-wrapper">
              <a className="anchor logo-anchor" href="/">
                <picture className="logo">
                  <img
                    srcSet={LogoWhite}
                    alt="Links 4 Engineering"
                  />
                </picture>
              </a>
            </div>
            <div id="menuToggle">
              <input className="sidebar" type="checkbox" />
              <span></span>
              <span></span>
              <ul id="menu">
                <Link to="/aboutus" className={location.pathname === "/aboutus" ? "active" : ""}>
                  <li>About Us</li>
                </Link>
                <Link to="/services" className={location.pathname === "/services" ? "active" : ""}>
                  <li>Services</li>
                </Link>
                <Link to="/technologies" className={location.pathname === "/technologies" ? "active" : ""}>
                  <li>Technologies</li>
                </Link>
                <Link to="/portfolio" className={location.pathname === "/portfolio" ? "active" : ""}>
                  <li>Portfolio</li>
                </Link>
                <Link to="/careers" className={location.pathname === "/careers" ? "active" : ""}>
                  <li>Careers</li>
                </Link>
                <Link to="/contactus" className={location.pathname === "/contactus" ? "active" : ""}>
                  <li>Contact Us</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="navbar-wrapper">
            <div className="container">
              <nav className="nav-bar">
              <div className="logo-wrapper">
                <a className="anchor logo-anchor" href="/">
                  <picture className="logo">
                    <img
                      srcSet={LogoBlue}
                      alt="LINKS4ENGG"
                      className="img-hover"
                    />
                    <img
                      srcSet={LogoWhite}
                      alt="LINKS4ENGG"
                      className="logo-white"
                    />
                  </picture>
                </a>
              </div>
                <ul className="parent">
                  <li>
                  <a href="#top">
                      <Link to="/aboutus" className={location.pathname === "/aboutus" ? "active" : ""} onClick={() => {
                        window.scroll(0, 0);
                      }}>
                      About Us
                      </Link>
                      </a>
                  </li>
                  <li>
                    <Link to="/services" className={location.pathname === "/services" ? "active" : ""} onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    <a href="#top">Services</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/technologies" className={location.pathname === "/technologies" ? "active" : ""} onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Technologies
                    </Link>
                  </li>
                  <li>
                    <Link to="/portfolio" className={location.pathname === "/portfolio" ? "active" : ""}onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers" className={location.pathname === "/careers" ? "active" : ""} onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Careers
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus" className={location.pathname === "/contactus" ? "active" : ""} onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
